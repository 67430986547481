import React, { Component } from 'react';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom'
import ReactPixel from 'react-facebook-pixel';
import Loading from './components/utils/Loading';
import MainApp from './components/main_screens/main_app';
import SeasonLong from './components/main_screens/season_long';
import Login from './components/main_screens/login';
import ImageViewer from './components/main_screens/image_viewer';
import DunkinPrizeViewer from './components/main_screens/dunkinPrizeViewer';
import AgeVerification from './components/main_screens/AgeVerification';
import {base} from './base';
import ReactGA from 'react-ga';

if(process.env.NODE_ENV === "production"){
  ReactGA.initialize(process.env.REACT_APP_GA_KEY);
  ReactPixel.init(process.env.REACT_APP_FB_PIXEL_ID);
}

function logPageView() {
  if(process.env.NODE_ENV === "production"){
    ReactGA.set({ page: window.location.pathname });
    ReactGA.pageview(window.location.pathname);
    ReactPixel.pageView();
      if(process.env.REACT_APP_FIREBASE_PROJECT_ID === "chicago-bulls-race"){
          console.log("TRACKS")
          ReactPixel.track('ViewContent', {content_type: 'dunkin'});
      }
  }
}

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            authenticated: false,
            variables: {},
            loading: true,
            passedEmail: false
        }
    }

    componentDidMount() {
        this.getTenantVariables();
    }

    getTenantVariables(){
        this.tenantVariablesRef = base.listenTo(`tenantVariables`, {
            context: this,
            state: 'tenantVariables',
            then(variables){
                document.title = variables.pageTitle || "Race";
                this.setState({
                    authenticated: false,
                    variables: variables,
                    loading: false
                })
            }
        });
    }

    componentWillUnmount() {
      base.removeBinding(this.tenantVariablesRef);
    }

    setPassedEmail(isSet=true){
      this.setState({
          passedEmail: isSet
      })
    }

    checkForAgeGate(tenantVariables){
        const variable_age_boolean = tenantVariables.collectBirthday || false;
        if(!variable_age_boolean){
            return true
        }
        const formBirthday = tenantVariables.formBirthday || false;
        let variable_age = tenantVariables.allowedAge || 21;
        variable_age = parseInt(variable_age, 10);
        let user_age;
        try {
            user_age = sessionStorage.getItem('verifiedAge')
        } catch (e) {
            user_age = false;
        }
        if(isNaN(parseInt(user_age))){
            user_age = false
        } else {
            user_age = parseInt(user_age, 10)
        }
        return (user_age && user_age >= variable_age) || formBirthday;
    }

    render() {
        if (this.state.loading === true) {
            return (
                <Loading loading={this.state.loading}/>
            )
        }
        return (
          <div style={{ margin: "0 auto"}}>
            <BrowserRouter onUpdate={logPageView()}>
                <div>
                    <div className="main-content">
                        <div className="workspace">
                          <Switch>
                            <Route
                                exact
                                path="/login"
                                render={(props) => {
                                    return <Login variables={this.state.variables} setPassed={(setPassed)=>this.setPassedEmail(setPassed)} checkForAgeGate={this.checkForAgeGate} {...props} />
                                }}
                            />

                              <Route
                                  exact
                                  path="/imageviewer"
                                  render={(props) => {
                                      return <ImageViewer {...props} />
                                  }}
                              />

                              <Route
                                  exact
                                  path="/rewardViewer"
                                  render={(props) => {
                                      return <DunkinPrizeViewer variables={this.state.variables} {...props} />
                                  }}
                              />

                            <Route
                                exact
                                path="/"
                                render={(props) => {
                                    return <MainApp variables={this.state.variables} passedEmail={this.state.passedEmail} checkForAgeGate={this.checkForAgeGate} {...props} />
                                }}
                            />

                            <Route
                                exact
                                path="/seasonlong"
                                render={(props) => {
                                    return <SeasonLong variables={this.state.variables} passedEmail={this.state.passedEmail} {...props} />
                                }}
                            />

                            <Route
                                exact
                                path="/age_gate"
                                render={(props) => {
                                    return <AgeVerification variables={this.state.variables} checkForAgeGate={this.checkForAgeGate} {...props} />
                                }}
                            />

                            <Redirect to='/login'/>
                          </Switch>
                        </div>
                    </div>
                </div>
            </BrowserRouter>
          </div>
        )
    }
}

export default App;
