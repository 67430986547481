import React, {Component} from 'react';
import {getURLParameter} from "../utils/HelpfulFunction";
import Loading from "../utils/Loading";
import {confirmAlert} from "../utils/react-confirm-alert";
import hotCoffeeImage from "../../styles/images/hot_cuppy.jpeg";
import bagelTemplateImage from "../../styles/images/bears_free_bagel.jpg";
import hotCoffeeTemplateImage from "../../styles/images/bears_hot_coffee.jpg";
import donutTemplateImage from "../../styles/images/bears_donut.jpg";
import iceCoffeeTemplateImage from "../../styles/images/bears_ice_coffee.jpg";
import '../../styles/css/DunkinFont.css';

class DunkinPrizeViewer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            imageToShow: "",
            imageTimeGiven: ""
        };
    }

    handleError(){

    }

    componentDidMount() {
        const code = getURLParameter('code');
        const id = getURLParameter('id');
        const winner = getURLParameter('winner');
        fetch(`https://us-central1-${process.env.REACT_APP_FIREBASE_PROJECT_ID}.cloudfunctions.net/getDunkinReward`, {
        // fetch(`http://localhost:5001/dunkinracedev/us-central1/getDunkinReward`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({code: code, id: id, winner: winner}),
        }).then(res => {
            if (res) {
                const vm = this;
                Promise.resolve(res.json()).then(function (value) {
                    let templateImage;
                    if(winner.indexOf("donut") !== -1){
                        templateImage = donutTemplateImage;
                    } else if(winner.indexOf("hot") !== -1) {
                        templateImage = hotCoffeeTemplateImage;
                    } else if(winner.indexOf("ice") !== -1){
                        templateImage = iceCoffeeTemplateImage;
                    } else if(winner.indexOf("bagel") !== -1) {
                        templateImage = bagelTemplateImage;
                    }
                    if(value && !value.error){
                        vm.setState({
                            imageToShow: value.image,
                            imageTimeGiven: value.timeGiven,
                            templateImage: templateImage
                        })
                    } else if(value && value.info === "expired") {
                        confirmAlert({
                            variables: vm.props.variables,
                            title: "Oh No!",
                            message: "This coupon is past its expiration date!",
                            buttons: [
                                {
                                    label: 'OK'
                                }
                            ]
                        })
                    } else {
                        confirmAlert({
                            variables: vm.props.variables,
                            title: "Error",
                            message: "Something Went Wrong",
                            buttons: [
                                {
                                    label: 'OK'
                                }
                            ]
                        })
                    }
                    vm.setState({
                        loading: false
                    })
                })
            }
        }).catch(e =>{
            confirmAlert({
                variables: this.props.variables,
                title: "Error",
                message: "Something Went Wrong",
                buttons: [
                    {
                        label: 'OK'
                    }
                ]
            })
            this.setState({
                loading:false
            })
        })
    }

    getDateTime(date) {
        const year = date.getFullYear();
        let month = date.getMonth() + 1;
        month = (month < 10 ? "0" : "") + month;
        let day  = date.getDate();
        day = (day < 10 ? "0" : "") + day;
        return month + "/" + day + "/" + year;
    }

    render() {
        let expirationDate = "";
        let templateImage = this.state.templateImage || "";
        if(this.state.imageTimeGiven){
            expirationDate = new Date(this.state.imageTimeGiven).setDate(new Date(this.state.imageTimeGiven).getDate() + 7);
            expirationDate = this.getDateTime(new Date(expirationDate));
        }
        if(this.state.loading === true){
            return (
                <Loading primaryColor="#FFF"/>
            )
        }
        return (
            <div style={{textAlign:'center'}}>
                {/*<div style={{position: "relative", textAlign: 'center', display: "inline-block"}}>*/}
                {/*    <img src={templateImage} style={{maxWidth: "100%", maxHeight: "100vh"}} alt=""/>*/}
                {/*    <img style={{width: "46%", height: "auto", position: 'absolute', top: "82.5%", left: "27%"}} src={this.state.imageToShow}/>*/}
                {/*    <p style={{fontFamily: "dunkin", position: 'absolute', top: "68%", left: "10%", color: "white", fontSize: "2vh"}}>{expirationDate}</p>*/}
                {/*</div>*/}
                <div style={{position: "relative", textAlign: 'center', display: "inline-block"}}>
                    <img src={templateImage} style={{maxWidth: "100%", maxHeight: "100vh"}} alt=""/>
                    <img style={{width: "42%", height: "auto", position: 'absolute', top: "82.7%", left: "29%", backgroundColor: "white", padding: "5px 4.8% 3px"}} src={this.state.imageToShow}/>
                    <p style={{fontFamily: "dunkin", position: 'absolute', top: "68%", left: "10%", color: "white", fontSize: "2vh"}}>{expirationDate}</p>
                </div>
            </div>
        )
    }
}

export default DunkinPrizeViewer;
